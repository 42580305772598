import React from 'react'
import {Footer} from 'src/components/Footer'
import {Header} from 'src/components/Header'
import 'src/newstyles.scss'
import { createGlobalStyle } from "styled-components"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  .flex-grow-infinite{
    margin-top: -8em;
    flex: 1;
    align-items: stretch;
  }
  .body-flex{	
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .panorama-wrapper{
    flex: 1 1 100%;
    position: relative;
    background-image: url('/src/images/home/0060_1600.jpg');
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center; 
      background-size: contain;
      .gatsby-image-wrapper{
        height: 100%;
        width: 100%;
      }
  }
  .content-wrapper{
    flex: auto;
    width: 100%;
    min-height: 0;
    position: relative;
  }
  .fillupbg{
    height: 100%;
    width: 100%;

  }
`

const Filler = styled.div`
  position: absolute;
  height: 50vh;
  width: 100%;
  margin: auto;
  overflow: hidden;
  .gatsby-image-wrapper{
    height: 100%;
    width: 100%;
  }
`


const Index = ({data, location}) => {
  
  const cover = data.allFile.nodes[0].childImageSharp.fluid
  
  return (<>
  <GlobalStyle theme="purple" />
    <body>
    <div className='body-flex flex-vertical'>
    <Header location={location}/>
    <div className='content-wrapper flex-grow-infinite flex-horizontal'>
    <Filler>
      {/* <StyledBackgroundSection
    Tag="section"
    className="fillupbg"
    fluid={cover}
    >
    </StyledBackgroundSection> */}
    <Img fluid={cover} imgStyle={{objectFit: 'contain'}}/>
    </Filler>
    </div>
    <div className='footer-wrapper'>
    <Footer/>
    </div>
    </div>
    </body>
  </>
  )
}

export const data = graphql`
  query IndexFr {
    allFile( filter: {relativeDirectory: {eq: "home"}}) {
      nodes {
        id
        childImageSharp {
          fixed(width: 125, height: 125) {
            ...GatsbyImageSharpFixed
          }
          fluid(maxWidth: 1600) {
            aspectRatio
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }`



export default Index